import { Component, OnDestroy, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { SessionCheckService } from "@lcs/authentication/session-check.service";
import { ErrorMessageService } from "@lcs/error-message/error-message.service";
import { LcsProgressButtonStatus } from "@lcs/progress-button/progress-button-status.enum";
import { markFormGroupAsTouchedAndDirty } from "@lcs/utils/form-utils";
import { WorkflowSteps } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/workflowsteps.enum";
import { EmailAddressSummaryModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/email-address-summary.model";
import { PhoneNumberModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/phone-number.model";
import { TextingSystemPreferencesModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/texting-system-preferences-model";
import { VerifyEmailAddressViewModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/verify-emailaddress-view.model";
import { SystemWebPreferencesService } from "projects/libraries/owa-gateway-sdk/src/lib/services/systemwebpreferences.service";
import { VerifyEmailAddressService } from "projects/libraries/owa-gateway-sdk/src/lib/services/verify-emailaddress.service";
import { Subject, takeUntil } from "rxjs";

import { AuthenticationService } from "../authentication/authentication.service";
import { OwaAuthenticationModel } from "../authentication/models/owa-authentication.model";

@Component({
   selector: "owa-verify-emailaddress",
   templateUrl: "./verify-emailaddress.component.html",
   styleUrls: ["./verify-emailaddress.component.css"],
})
export class VerifyEmailAddressComponent implements OnInit, OnDestroy {
   public authModel: OwaAuthenticationModel = new OwaAuthenticationModel();
   emailAddressViewModel = new EmailAddressSummaryModel();
   phoneNumber: string = "";
   phoneNumberModel = new PhoneNumberModel();
   errorMessage: string;
   textingSystemPreferencesModel = new TextingSystemPreferencesModel();

   model = new VerifyEmailAddressViewModel();

   sourceStep: boolean;

   verifyButtonStatus: LcsProgressButtonStatus = LcsProgressButtonStatus.Unset;
   showProfileInfoFields: boolean = false;

   private token: string;

   private unsubscribe = new Subject<void>();

   constructor(
      private activatedRoute: ActivatedRoute,
      private authenticationservice: AuthenticationService,
      private verifyEmaiAddressService: VerifyEmailAddressService,
      private errorMessageService: ErrorMessageService,
      private router: Router,
      private sessionCheckService: SessionCheckService,
      private systemWebPreferenceService: SystemWebPreferencesService
   ) {
      sessionCheckService.checkSessionOnFocus = false;
   }

   ngOnInit() {
      this.token = this.activatedRoute.snapshot.queryParams.token;

      if (this.token != null) {
         this.verifyEmaiAddressService
            .getTokenInformation(encodeURIComponent(this.token))
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(
               (res) => {
                  if (res) {
                     this.systemWebPreferenceService
                        .GetThemeColorSystemWebPreference(res.LocationID)
                        .pipe(takeUntil(this.unsubscribe))
                        .subscribe((prefs) => {
                           if (prefs != null) {
                              this.authenticationservice.setTheme(prefs);
                           }
                           if (res.SourceStep === WorkflowSteps[WorkflowSteps.RecoverAccount]) {
                              this.router.navigate(["/password/reset"], { queryParams: { token: this.token } });
                              return;
                           }
                           this.emailAddressViewModel = res;
                           if (res.PhoneNumbers.length > 0) {
                              this.phoneNumber = res.PhoneNumbers[0].PhoneNumber;
                           }
                           this.sourceStep =
                              this.emailAddressViewModel.SourceStep === WorkflowSteps[WorkflowSteps.Signup]
                                 ? true
                                 : false;
                           if (!res.FirstName || !res.LastName) {
                              this.showProfileInfoFields = true;
                           }
                        });
                  }
               },
               (error) => {
                  this.errorMessage = this.errorMessageService.processHttpError(error).Message;
               }
            );
            this.authenticationservice.loginResults.pipe(takeUntil(this.unsubscribe)).subscribe((loginSuccessful) => {
               if (loginSuccessful != null) {
                  if (!loginSuccessful.ok) {
                     this.errorMessageService.triggerHttpErrorMessage(loginSuccessful);
                     this.router.navigateByUrl("/login");
                  } 
               }
            });
      }

      this.verifyEmaiAddressService
         .getTextingPreferences(encodeURIComponent(this.token))
         .pipe(takeUntil(this.unsubscribe))
         .subscribe((res) => {
            this.textingSystemPreferencesModel = res;
            if (res.TextingPrivacyPolicyLink === "") {
               this.textingSystemPreferencesModel.TextingPrivacyPolicyLink = "privacy policy";
            } else {
               this.textingSystemPreferencesModel.TextingPrivacyPolicyLink =
                  "<a href='" + res.TextingPrivacyPolicyLink + "' target='_blank'>privacy policy</a>";
            }
            if (res.TextingToSLink === "") {
               this.textingSystemPreferencesModel.TextingToSLink = "terms of service";
            } else {
               this.textingSystemPreferencesModel.TextingToSLink =
                  "<a href='" + res.TextingToSLink + "' target='_blank'>terms of service</a>";
            }
         });
   }

   ngOnDestroy(): void {
      this.sessionCheckService.checkSessionOnFocus = true;
      this.unsubscribe.next();
   }

   verify(form: NgForm) {
      if (form.valid) {
         this.verifyButtonStatus = LcsProgressButtonStatus.InProgress;
         if (this.emailAddressViewModel.PhoneNumbers.length > 0) {
            this.emailAddressViewModel.PhoneNumbers[0].PhoneNumber = this.phoneNumber;
         } else {
            this.phoneNumberModel.PhoneNumber = this.phoneNumber;
            this.emailAddressViewModel.PhoneNumbers.push(this.phoneNumberModel);
         }
         this.verifyEmaiAddressService
            .verifyEmailAddress(this.emailAddressViewModel)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(
               (success) => {
                  if (success) {
                     const returnEmailAddressViewModel: EmailAddressSummaryModel = success;
                     if (returnEmailAddressViewModel.Message) {
                        this.verifyButtonStatus = LcsProgressButtonStatus.Error;
                        this.errorMessageService.triggerErrorMessage(returnEmailAddressViewModel.Message);
                     } else if (returnEmailAddressViewModel.SourceStep === WorkflowSteps[WorkflowSteps.MergeUsers]) {
                        this.verifyButtonStatus = LcsProgressButtonStatus.Success;
                        this.router.navigateByUrl("/mergeusers");
                     } else if (
                        returnEmailAddressViewModel.SourceStep ===
                        WorkflowSteps[WorkflowSteps.AccountUpdatedSplashScreen]
                     ) {
                        this.verifyButtonStatus = LcsProgressButtonStatus.Success;
                        this.authModel.Username = this.emailAddressViewModel.DesiredUsername;
                        this.authModel.Password = this.emailAddressViewModel.Password;
                        this.authModel.LocationId = this.emailAddressViewModel.LocationID;
                        this.authenticationservice.logIn(this.authModel);
                     }
                  }
               },
               (error) => {
                  this.verifyButtonStatus = LcsProgressButtonStatus.Error;
                  this.errorMessageService.triggerHttpErrorMessage(error);
               }
            );
      } else {
         this.verifyButtonStatus = LcsProgressButtonStatus.Warning;
         markFormGroupAsTouchedAndDirty(form.control);
      }
   }
}
